<template>
  <div>
    <el-tree
      ref="tree"
      :data="data"
      :node-key="key1"
      @check-change="checkChange"
      :props="defaultProps"
      :load="loadNode1"
      :check-strictly="true"
      lazy
      @node-click="handleNodeClick"
      show-checkbox
    ></el-tree>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nowCheckedkeys: "",
      checkdChanged: false,
      nowTime: 1001,
      lastTime: 1000,
      deptId: "",
      curChecked: [],
      typeChangeFlag: false,
      dialogVisible: false,
      treeType: "1",
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1"
                }
              ]
            }
          ]
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1"
                }
              ]
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1"
                }
              ]
            }
          ]
        },
        {
          label: "一级 3",
          children: [
            {
              label: "二级 3-1",
              children: [
                {
                  label: "三级 3-1-1"
                }
              ]
            },
            {
              label: "二级 3-2",
              children: [
                {
                  label: "三级 3-2-1"
                }
              ]
            }
          ]
        }
      ],
      cityList: [], // 查找对应市
      cityListobj: [], 
      areaList: {}, // 对应区
      iscont: 0,
      issum: 0,
    };
  },
  watch: {
    iscont(val, old) {
      console.log(this.iscont, this.issum);
      if (this.iscont == this.issum) {
        this.$axios.get("/acb/2.0/area/getAllArea").then(res => {
          let arrobj = res.value;
          arrobj[0].childrenAreas = this.cityListobj;
          this.data = this.setIsDeabled(arrobj);
        });
      }
    }
  },
  props: {
    treeData: {
      default: function() {
        return [];
      }
    },
    defaultProps: {
      default: function() {
        return {
          label: "areaName",
          children: "childrenAreas",
          isLeaf: "leaf"
        };
      }
    },
    key1: {
      default: "areaId"
    },
    selectParentList: {
      default: function() {
        return [];
      }
    }
  },
  methods: {
    checkChange(node) {
      this.curChecked = this.$refs.tree.getCheckedKeys();
      this.checkdChanged = true;
      // 给初始状态设置成字符串，如果是字符串就赋值，如果赋过值就直接拿来用
      // 判断以前数组里是否有现在点击的节点，有删无添
      if (typeof this.nowCheckedkeys === 'string') {
        let keys = Object.keys(this.selectParentList)
        let b = []
        if (keys.length > 0) {
          keys.forEach(item => {
            b = [...b, ...this.selectParentList[keys]]
          })
        }
        this.nowCheckedkeys = [...b]
        if (this.nowCheckedkeys.includes(node.areaId)) {
          let i = this.nowCheckedkeys.indexOf(node.areaId)
          this.nowCheckedkeys.splice(i, 1)
        } else {
        this.nowCheckedkeys.push(node.areaId);
      }
      } else {
        if (this.nowCheckedkeys.includes(node.areaId)) {
          let i = this.nowCheckedkeys.indexOf(node.areaId)
          this.nowCheckedkeys.splice(i, 1)
        } else {
        this.nowCheckedkeys.push(node.areaId);
      }
      }
    },
    setKeys(arr) {
      this.curChecked = arr;
      this.$refs.tree.setCheckedKeys(arr);
    },
    setChangeFlag() {
      this.typeChangeFlag = true;
    },
    setTreeType(type, deptId) {
      if (deptId) {
        this.deptId = deptId;
      }
      this.treeType = type;
      if (this.deptId == 1) {
        this.getArea();
      } else {
        this.nowTime = new Date().getTime();
        if (this.nowTime - this.lastTime < 1000) {
           return
        } else {
          this.lastTime = new Date().getTime();
          this.getAreaNew();
        } ;
      }
      this.setKeys([]);
    },
    setIsleaf(data) {
      // console.log(data)
      data.forEach(v => {
        if (v.childrenAreas && v.childrenAreas.length) {
          v.disabled = true;
          this.setIsleaf(v.childrenAreas);
        } else {
          v.leaf = true;
        }
      });
      return data;
    },
    setIsDeabled(data) {
      data.forEach(v => {
        v.disabled = true;
        if (v.childrenAreas && v.childrenAreas.length) {
          this.setIsDeabled(v.childrenAreas);
        }
      });
      return data;
    },
    loadNode1(node, resolve) {
      if (node.data.childrenAreas && node.data.childrenAreas.length) {
        resolve(node.data.childrenAreas);
        if (this.typeChangeFlag) return;
        this.setKeys([
          ...this.filterNode(this.curChecked, node.data.areaId),
          ...this.selectParentList[node.data.areaId]
        ]);
        return;
      }
      if (node.level == 0) {
        this.getArea(node, resolve);
      } else if (this.treeType == 2 && node.data.depth <= 3) {
        node.checked = false;
        node.data.disabled = true;
        this.getChildren(node, resolve);
      } else {
        resolve([]);
      }
    },
    getArea(node, resolve) {
      let url = "/acb/2.0/systems/loginUser/getAllArea";
      this.$axios.get(url).then(res => {
        if (res.state == 0) {
          // if (this.treeType == 1) {
          //    this.setIsleaf(res.value);
          //   } else {
          //     this.setIsDeabled(res.value);
          //   }
          if (resolve) {
            if (this.treeType == 1) {
              resolve(this.setIsleaf(res.value));
            } else {
              resolve(this.setIsDeabled(res.value[0]));
            }
          } else {
            if (this.treeType == 1) {
              this.data = this.setIsleaf(res.value);
            } else {
              this.data = this.setIsDeabled(res.value);
            }
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    // 权限过滤 新
    getAreaNew(node, resolve) {
      let url = "/acb/2.0/bossManagerRole/deptProjectDetail";
      this.$axios.post(url, {
          data: {
            deptId: this.deptId,
          }
        }).then(res => {
        if (res.state == 0) {
          // if (this.treeType == 1) {
          //    this.setIsleaf(res.value);
          //   } else {
          //     this.setIsDeabled(res.value);
          //   }
          // 数据为空
          if (!res.value.deptManagerAreaVoList) {
            this.data = [];
            return;
          }
          if (resolve) {
            if (this.treeType == 1) {
              this.$axios.get("/acb/2.0/area/getAllArea").then(data => {
                let arrobj = data.value;
                arrobj[0].childrenAreas = res.value.deptManagerAreaVoList;
                resolve(this.setIsleaf(arrobj));
              });
            } else {
              // resolve(this.setIsDeabled(res.value.deptManagerAreaVoList[0]));
            }
          } else {
            if (this.treeType == 1) {
              this.$axios.get("/acb/2.0/area/getAllArea").then(data => {
                let arrobj = data.value;
                arrobj[0].childrenAreas = res.value.deptManagerAreaVoList;
                this.data = this.setIsleaf(arrobj);
              });
            } else {
              this.returnCity(res.value.deptManagerAreaVoList);
            }
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    // 递归获取对应城市
    returnCity(deptArea) {
      this.cityList = []; // 查找对应市
      this.cityListobj = []; 
      this.areaList = {}; // 对应区
      this.iscont = 0;
      this.issum = deptArea.length;
      for (let index = 0; index < deptArea.length; index++) {
        const dept = deptArea[index];
        this.$axios.post("/acb/2.0/bossManagerRole/cityDetail", {
          data: {
            areaId: dept.areaId
          }
        }).then(res => {
          if (this.cityList.indexOf(res.value.areaId) == -1) {
            this.cityList.push(res.value.areaId);
            this.cityListobj.push(res.value);
            this.areaList[res.value.areaId] = [];
            this.areaList[res.value.areaId].push(dept);
          } else {
            this.areaList[res.value.areaId].push(dept);
          }
          this.iscont++
        });
      }
    },
    // 过滤掉被选中的节点
    filterNode(arr, aim) {
      return arr.filter(v => {
        return v != aim;
      });
    },
    getChildren(node, resolve) {
      if (this.deptId != '1' && this.treeType != 1) {
        resolve(this.setIsleaf(this.areaList[node.data.areaId]));
        if (Object.keys(this.selectParentList).length) {
          this.setKeys([
            ...this.filterNode(this.curChecked, node.data.areaId),
            ...this.selectParentList[node.data.areaId]
          ]);
        }
      } else  {
        let url = "/acb/2.0/systems/loginUser/getChildrenArea";
        this.$axios
          .get(url, {
            data: {
              areaId: node.data.areaId
            }
          })
          .then(res => {
            if (res.state == 0) {
              resolve(this.setIsleaf(res.value));
              if (Object.keys(this.selectParentList).length) {
                this.setKeys([
                  ...this.filterNode(this.curChecked, node.data.areaId),
                  ...this.selectParentList[node.data.areaId]
                ]);
              }
              // this.treeData = this.sortArea(res.value);
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
            }
          });
      }
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    handleNodeClick() {},
    sureButton() {
      // console.log(5452, this.typeChangeFlag, this.selectParentList);
      // this.checkdChanged = true
      if (this.typeChangeFlag) { // 按街还是按区域是否有值
        let keys = Object.keys(this.selectParentList)
        let b = [] // b就是传过来的值
        if (keys.length > 0) {
          keys.forEach(item => {
            b = [...b, ...this.selectParentList[keys]]
          })
        }
          let a = this.checkdChanged ? this.nowCheckedkeys : b
        // let a = this.$refs.tree.getCheckedKeys().length > 0 ? this.$refs.tree.getCheckedKeys() : b.length > 0 ? b : []
        // console.log(a, b, 5455)
        return a;
      }
    }
  },
  created() {
    //   this.getArea()
  },
  mounted() {}
};
</script>
<style scoped>
.dialog-footer {
  text-align: center;
}
</style>
