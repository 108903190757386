<template>
  <div class="page1 administratorManageAE">
    <!--主体内容-->
    <div class="content">
      <TitleModule :title="'添加/修改管理员'"></TitleModule>
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form label-position="right" label-width="130px" :model="formInline" :rules="rules" ref="form">
          <el-form-item :label="$t('searchModule.phone_number')" prop="mobile">
            <el-input :maxlength="11" style="width: 220px;" v-model.trim="formInline.mobile" :disabled="!isEdit" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Login_Name')" prop="account">
            <el-input :maxlength="20" style="width: 220px;" v-model.trim="computedAccount" :disabled="isEdit" placeholder="登录名"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.full_name')" prop="bossManagerName">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.bossManagerName"
              auto-complete="off"
              placeholder="请输入姓名"
              style="width: 220px;"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.sex')" prop="sex">
            <el-radio-group v-model="formInline.sex">
              <el-radio :label="1" value="1">男</el-radio>
              <el-radio :label="0" value="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('searchModule.mailbox')" prop="email">
            <el-input
              :maxlength="50"
              v-model.trim="formInline.email"
              auto-complete="off"
              placeholder="请输入邮箱"
              style="width: 220px;"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Department_to')" prop="deptId">
            <el-cascader
              :options="treeData"
              expand-trigger="hover"
              filterable
              clearable
              :show-all-levels="false"
              v-model="formInline.deptIdArr"
              :props="defaultPropsCascader"
              change-on-select
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item :label="$t('searchModule.role')" prop="bossManagerRoleId" v-show="formInline.deptId">
            <el-select placeholder="请选择" v-model.trim="formInline.bossManagerRoleId" style="width: 220px;">
              <el-option
                :label="value.bossManagerRoleName"
                :value="value.bossManagerRoleId"
                :key="value.bossManagerRoleId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管辖方式" prop="manageType" v-show="formInline.deptId">
            <el-select v-model.trim="formInline.manageType" @change="typeChange" style="width: 220px;" :disabled="formInline.deptId != 1">
              <!-- <el-option label="按城市管理" value="1"></el-option>
              <el-option label="按城区管理" value="2"></el-option> -->
              <el-option :label="v.desc" :value="v.code" :key="v.code" v-for="v in manageList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管辖区域" v-show="formInline.deptId">
            <areaTree ref="areaTreeCon" :selectParentList="selectParentList"></areaTree>
          </el-form-item>
          <el-form-item label="管辖车场接入方式" prop="parkAccessType" class="changeWith">
            <el-radio-group v-model.trim="formInline.parkAccessType">
              <!-- <el-radio :label="0" value="1">全部</el-radio>
              <el-radio :label="1" value="1">平台接入</el-radio>
              <el-radio :label="2" value="2">三方接入</el-radio> -->
              <div v-for="v in radioList" :key="v.code" style="float:left;margin-right:10px;">
                <el-radio :label="v.code">{{ v.desc }}</el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="管辖区域">
          <el-tree :ref="'tree'" :load="loadNode1" :default-checked-keys="defaultCheckedKeys" :default-expand-all = "true" :check-strictly = "true" :default-expanded-keys="['北京市']" node-key="areaName"  lazy :props="defaultProps" show-checkbox ></el-tree>
        </el-form-item> -->
          <el-form-item label="移动管理角色" prop="manageRole">
            <el-select v-model="formInline.mobileRole" style="width: 220px;" @change="mobileRoleChange">
              <el-option v-for="item in mangerRoleType" :label="item.desc" :value="item.code" :key="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="移动端管辖区域" prop="mobileArea" v-if="formInline.mobileRole !== 3">
            <el-select v-model.trim="formInline.mobileArea" style="width: 220px;">
              <el-option label="请选择" value="" v-if="formInline.mobileRole === 2"></el-option>
              <el-option label="AIpark" value="" v-if="formInline.mobileRole === 1"></el-option>
              <el-option v-if="formInline.mobileRole === 2" v-for="item in this.areaList" :label="item.areaName" :value="item.areaId" :key="item.areaId" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="失效时间"
                        prop='date'>
            <el-date-picker v-model="formInline.effectiveDate"
                            type="date"
                            value-format='yyyy-MM-dd'
                            placeholder="选择日"
                            @change="page=1">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 20px; ">
          <el-button type="primary" @click="submitData" style="width: 88px;">{{ $t('button.preservation') }}</el-button>
          <el-button type="" @click="$router.go(-1)" style="width: 88px;">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import areaTree from "../areaTree";
import TitleModule from "@/components/titleModule/titleModule";
export default {
  data() {
    const generateData2 = _ => {
      const data = [];
      const cities = ["上海", "北京", "广州", "深圳", "南京", "西安", "成都"];
      const pinyin = ["shanghai", "beijing", "guangzhou", "shenzhen", "nanjing", "xian", "chengdu"];
      cities.forEach((city, index) => {
        data.push({
          label: city,
          key: index,
          pinyin: pinyin[index]
        });
      });
      return data;
    };
    return {
      deptTree: [], // 全集所有部门
      mangerRoleType: [],
      manageList: [],
      radioList: [],
      treeData: [],
      checkArr: [],
      defaultPropsCascader: {
        children: "childNodes",
        label: "deptName",
        value: "deptId",
        checkStrictly: true
      },
      selectParentList: [],
      isEdit: false,
      roleList: [],
      areaList: [],
      defaultCheckedKeys: [],
      areaListChild: [],
      defaultProps: {
        children: "childrenAreas",
        label: "areaName",
        disabled: (data, node) => {
          if (data.depth == 2) {
            return true;
          }
          if (this.formInline.manageType == 1) {
            if (data.depth > 3) {
              return true;
            }
          } else if (this.formInline.manageType == 2) {
            if (data.depth <= 3) {
              return true;
            }
          }
        }
      },
      data2: generateData2(),
      value2: [],
      filterMethod(query, item) {
        return item.pinyin.indexOf(query) > -1;
      },
      imageUrl: "",
      formInline: {
        bossManagerName: "",
        bossManagerRoleId: "",
        manageType: "",
        account: "",
        mobile: "",
        email: "",
        sex: 1,
        parkAccessType: "",
        arealists: "",
        deptId: "",
        deptIdArr: [],
        mobileRole: 3,
        mobileArea: "",
        effectiveDate: '',
        manageRole: "",
        manageArea: ""
      },
      rules: {
        account: [
          {
            required: true,
            message: "请输入登录名",
            trigger: "blur"
          },
          {
            validator: (rule, value, callback) => {
              if (!/^(\w){2,20}$/.test(value)) {
                callback(new Error("2~20位英文或数字或下划线组合"));
              } else if (/^\d{2,20}$/.test(value) || /^_{2,20}$/.test(value)) {
                callback(new Error("2~20位英文或数字或下划线组合"));
              } else {
                this.$axios.get("/acb/2.0/bossManager/checkaccount/" + value).then(res => {
                  if (this.isEdit && this.oldPhone == value) {
                    callback();
                  } else if (!res.value) {
                    callback(new Error("该用户名已经被使用"));
                  } else if (res.value) {
                    callback();
                  } else {
                    callback(new Error(res.desc));
                  }
                });
              }
            },
            trigger: "blur"
          }
        ],
        deptId: [
          {
            required: true,
            message: "请选择部门",
            trigger: "blur"
          }
        ],
        bossManagerName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        bossManagerRoleId: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur"
          }
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback();
              } else if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/g.test(value)) {
                return callback(new Error("该邮箱格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: this.isEdit,
            message: "请输入手机号码",
            trigger: "blur"
          },
          {
            validator: (rule, value, callback) => {
              if (!this.isEdit) {
                callback();
              } else {
                if (!/^\d{11}$/.test(value)) {
                  callback(new Error("手机号格式不正确"));
                } else {
                  this.$axios.get("/acb/2.0/bossManager/checkmobile/" + value).then(res => {
                    if (this.isEdit) {
                      callback();
                    } else if (!res.value) {
                      callback(new Error("该手机号已经被使用"));
                    } else if (res.value) {
                      callback();
                    } else {
                      callback(new Error(res.desc));
                    }
                  });
                }
              }
            },
            trigger: "blur"
          }
        ],
        bannerImg: [
          {
            required: true,
            message: "请选择图片",
            trigger: "blur"
          }
        ],
        transitionType: [
          {
            required: true,
            message: "请选择跳转类型",
            trigger: "blur"
          }
        ],
        linkUrl: [
          {
            required: true,
            message: "请选择链接",
            trigger: "blur"
          }
        ],
        startTime: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "blur"
          }
        ],
        endTime: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    computedAccount: {
      get: function() {
        return this.formInline.account;
      },
      set: function(val) {
        this.formInline.account = val.toLowerCase();
      }
    }
  },
  methods: {
    getDeptTree() {
      this.$axios.get("/acb/2.0/dept/getDeptTree").then((res) => {
        this.deptTree = [res.value];
        if (this.$route.query.bossManagerId) {
          this.formInline.bossManagerId = this.$route.query.bossManagerId;
          this.isEdit = true;
          this.oldPhone = this.$route.query.account;
          this.queryDetail();
        }
      });
    },
    mobileRoleChange(val) {
      if (val === 2) {
        this.formInline.mobileArea = this.areaList[0].areaId
      } else {
        this.formInline.mobileArea = ''
      }
    },
    // 获取所属部门
    getByCurrentUser() {
      this.$axios.get("/acb/2.0/dept/getByCurrentUser").then(res => {
        this.treeData = [];
        this.treeData.push(res.value);
        this.treeData = [res.value];
      });
    },
    handleChange(value) {
      this.formInline.bossManagerRoleId = ''
      if (value.length) {
         this.formInline.deptIdArr = value;
        this.formInline.deptId = value[value.length - 1];
        // if (value.length >= 2) {
        //   this.getRoleListNew(value[1]);
        //   this.getManageType(value[1]);
        // } else {
        //   this.formInline.manageType = 1;
        //   this.$refs.areaTreeCon.setTreeType(1, 1);
        //   this.$refs.areaTreeCon.setChangeFlag();
        //   this.getRoleListNew(this.formInline.deptId);
        // }
        this.getManageType(this.formInline.deptId)
        setTimeout(() => {
            this.returnLevel(this.formInline.deptId, this.deptTree);
        }, 150);
      } else {
        this.roleList = []
      }
    },
    typeChange(item) {
      // item => 1,2
      this.$refs.areaTreeCon.setTreeType(item);
      this.$refs.areaTreeCon.setChangeFlag();
    },
    // 获取角色列表
    getRoleList() {
      this.$axios
        .get("/acb/2.0/bossManagerRole/list", {
          data: {
            page: 1,
            size: 100
          }
        })
        .then(res => {
          this.roleList = res.value.list;
        });
    },
    // 获取角色列表----权限新
    getRoleListNew(deptId) {
      this.$axios
        .get("/acb/2.0/bossManagerRole/roleList", {
          data: {
            deptId: deptId,
          }
        })
        .then(res => {
          let arr = [];
          if (res.value.length > 0) {
            res.value.forEach(element => {
              Object.keys(element).forEach(function(key) {
                arr.push({
                  bossManagerRoleId: key,
                  bossManagerRoleName: element[key],
                })
              });
            });
          }
          this.roleList = arr;
        });
    },
    // 获取按区按市----权限新
    getManageType(deptId) {
      this.$axios
        .post("/acb/2.0/bossManagerRole/deptProjectDetail", {
          data: {
            deptId: deptId,
          }
        })
        .then(res => {
          this.formInline.manageType = res.value.manageType;
          this.$refs.areaTreeCon.setTreeType(this.formInline.manageType, deptId);
          this.$refs.areaTreeCon.setChangeFlag();
        });
    },
    // 懒加载显示子级
    loadNode1(node, resolve) {
      if (node.level === 0) {
        this.getArealist(resolve);
      } else if (node.level === 1) {
        resolve(this.areaList[0].childrenAreas);
      } else if (node.level > 1) {
        this.getChildrenArea(node.data.areaId, resolve);
      }
    },
    // 获取下级区域列表
    getChildrenArea(areaId, resolve) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: areaId
          }
        })
        .then(res => {
          if (res.value.length > 0) {
            this.areaListChild.push(res.value);
          }
          resolve(res.value);
        });
    },

    // 获取当前用户的区域列表
    getArealist(resolve) {
      this.$axios.get("/acb/2.0/area/getAllArea").then(res => {
        this.areaList = res.value[0].childrenAreas;
        // resolve(res.value);
      });
    },
    getInfo() {
      this.$axios.get("/acb/2.0/bossManager/spinner").then(res => {
        // console.log("列表")
        if (res.state == 0) {
          this.mangerRoleType = res.value.mangerRoleType;
          this.manageList = res.value.manageType;
          this.radioList = res.value.parkAccessType;
          this.formInline.manageType = res.value.manageType[0].code;
          this.formInline.parkAccessType = res.value.parkAccessType[0].code;
        }
      });
    },
    // 查找一个节点的所有父节点
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.deptId === id) {
            temp.push(item);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childNodes) {
              forFn(item.childNodes, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    queryDetail() {
      this.$axios.get("/acb/2.0/bossManager/getbyid/" + this.$route.query.bossManagerId).then(res => {
        if (res.state == 0) {
          for (let i in this.formInline) {
            this.formInline[i] = res.value[i];
          }
          if (!res.value.mobileArea) {
            this.formInline.mobileArea = ''
          }
          if (!res.value.mobileRole) {
            this.formInline.mobileRole = 3
          }
          if (!res.value.bossManagerRoleName) {
            this.formInline.bossManagerRoleId = "";
          }
          this.formInline.manageType = res.value.manageType;
          // console.log(this.formInline.manageType, '类型')
          let selectParentList = res.value.selectParentList;
          this.selectParentList = selectParentList;
          let arr = [];
          for (let i in selectParentList) {
            let selectVal = selectParentList[i]
            for (let y = 0; y < selectVal.length; y++) {
             arr.push(selectVal[y]);
            }
          }
          this.checkArr = arr;
          let deptArr = [];
            const prentArr = this.familyTree(this.deptTree, res.value.deptId);
            prentArr.forEach((item) => {
              // console.log(item, 'jjjj')
              deptArr.unshift(item.deptId);
            });
            this.formInline.deptIdArr = deptArr;
          // 判断部门归属二级一级
          setTimeout(() => {
            this.returnLevel(this.formInline.deptId, this.deptTree);
            this.$refs.areaTreeCon.setKeys(arr);
          }, 150);
          console.log(this.formInline, '回显数据')
          // if (res.value.manageType == 1) {
          //   this.$refs.areaTreeCon.setTreeType(1);
          // } else {
          //   this.$refs.areaTreeCon.setTreeType(2);
          // }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    submitData() {
      if (sessionStorage.resourceIds) {
        this.formInline.resourceIds = sessionStorage.resourceIds;
      }
      // this.createdUpdataBossManager();
      this.$refs.form.validate(valid => {
        if (valid) {
          // COMBAK:
          // if (!sessionStorage.resourceIds && !this.formInline.resourceIds) {
          //   this.$alert('没有配置权限', this.$t('pop_up.Tips'), {
          //     confirmButtonText: this.$t('pop_up.Determine')
          //   });
          //   return
          // }
          if (this.$refs.areaTreeCon.sureButton().length < 1) {
            this.$alert("请绑定区域！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return false;
          }
          if (this.formInline.deptId == "") {
            this.$alert("请选择所属部门！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return;
          }
          this.formInline.arealists = this.$refs.areaTreeCon.sureButton().join(",");
          this.createdUpdataBossManager();
        } else {
          return false;
        }
      });
    },
    createdUpdataBossManager() {
      this.$axios
        .post(this.isEdit ? "/acb/2.0/bossManager/update" : "/acb/2.0/bossManager/create", {
          data: this.formInline
        })
        .then(res => {
          if (res.state == 0) {
            this.$message({
              type: "info",
              message: "操作成功"
            });
            sessionStorage.resourceIds = "";
            sessionStorage.juese = "";
            this.$router.go(-1);
            sessionStorage.searchFlag = 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    },
    setResourse() {
      sessionStorage.juese = JSON.stringify(this.formInline);
      if (this.isEdit) {
        this.$router.push({
          path: "/configPermiss",
          query: this.$route.query
        });
      } else {
        this.$router.push({
          path: "/configPermiss"
        });
      }
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 递归取一、二级用户
    returnLevel(deptId, arr) {
      arr.forEach(ele1 => {
        if (ele1.deptId == deptId && ele1.level == 1) {
          // this.formInline.manageType = 1;
          this.setTreeConfig(this.formInline.deptId);
          this.getRoleListNew(this.formInline.deptId);
        } else if (ele1.deptId == deptId && ele1.level == 2) {
          this.setTreeConfig(this.formInline.deptId);
          this.getRoleListNew(this.formInline.deptId);
        }  else if (ele1.deptId == deptId && ele1.level > 2) {
          this.returnLevel22(ele1.parentId, this.deptTree);
        } else if (ele1.childNodes)  {
          this.returnLevel(deptId, ele1.childNodes);
        }
      });
    },
    // 递归通过三、四、、、取对应二级
    returnLevel22(parentId, arr) {
      arr.forEach(ele1 => {
        if (ele1.deptId == parentId && ele1.level == 2) {
          this.setTreeConfig(ele1.deptId);
          // this.getRoleListNew(ele1.deptId);
          this.getRoleListNew(this.formInline.deptId);
        } else if (ele1.deptId == parentId && ele1.level > 2)  {
          this.returnLevel22(ele1.parentId, this.deptTree);
        } else if (ele1.childNodes)  {
          this.returnLevel22(parentId, ele1.childNodes);
        }
      });
    },
    setTreeConfig(deptId) {
      if (!this.formInline.manageType) {
        this.getManageType(deptId)
      } else {
        if (this.formInline.manageType == 1) {
          this.$refs.areaTreeCon.setTreeType(1, deptId);
          this.$refs.areaTreeCon.setChangeFlag();
        } else {
          this.$refs.areaTreeCon.setTreeType(2, deptId);
          this.$refs.areaTreeCon.setChangeFlag();
        }
      }
    }
  },
  components: {
    TitleModule,
    areaTree
  },
  created() {
    this.getDeptTree();
    if (sessionStorage.juese) {
      this.formInline = JSON.parse(sessionStorage.juese);
      sessionStorage.juese = "";
    }
    this.getArealist()
    // 获取角色列表
    // this.getRoleList();
    // 获取所属部门
    this.getByCurrentUser();
  },
  mounted() {
    this.getInfo();
  }
};
</script>
<style media="screen">
.changeWith .el-form-item__label {
  width: 130px !important;
}
.administratorManageAE .el-tree-node__content {
  height: 40px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>
.breadcrumb {
  height: 35px;
}

.administratorManageAE .content {
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #0f6eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
