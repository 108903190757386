var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1 administratorManageAE" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("TitleModule", { attrs: { title: "添加/修改管理员" } }),
        _c(
          "div",
          { staticClass: "searchWrapper" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-position": "right",
                  "label-width": "130px",
                  model: _vm.formInline,
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.phone_number"),
                      prop: "mobile",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "220px" },
                      attrs: {
                        maxlength: 11,
                        disabled: !_vm.isEdit,
                        placeholder: "手机号",
                      },
                      model: {
                        value: _vm.formInline.mobile,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "mobile",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.mobile",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.Login_Name"),
                      prop: "account",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "220px" },
                      attrs: {
                        maxlength: 20,
                        disabled: _vm.isEdit,
                        placeholder: "登录名",
                      },
                      model: {
                        value: _vm.computedAccount,
                        callback: function ($$v) {
                          _vm.computedAccount =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "computedAccount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.full_name"),
                      prop: "bossManagerName",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "220px" },
                      attrs: {
                        maxlength: 20,
                        "auto-complete": "off",
                        placeholder: "请输入姓名",
                      },
                      model: {
                        value: _vm.formInline.bossManagerName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "bossManagerName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.bossManagerName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("searchModule.sex"), prop: "sex" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.formInline.sex,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "sex", $$v)
                          },
                          expression: "formInline.sex",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1, value: "1" } }, [
                          _vm._v("男"),
                        ]),
                        _c("el-radio", { attrs: { label: 0, value: "0" } }, [
                          _vm._v("女"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.mailbox"),
                      prop: "email",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "220px" },
                      attrs: {
                        maxlength: 50,
                        "auto-complete": "off",
                        placeholder: "请输入邮箱",
                      },
                      model: {
                        value: _vm.formInline.email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "email",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.Department_to"),
                      prop: "deptId",
                    },
                  },
                  [
                    _c("el-cascader", {
                      attrs: {
                        options: _vm.treeData,
                        "expand-trigger": "hover",
                        filterable: "",
                        clearable: "",
                        "show-all-levels": false,
                        props: _vm.defaultPropsCascader,
                        "change-on-select": "",
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.formInline.deptIdArr,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "deptIdArr", $$v)
                        },
                        expression: "formInline.deptIdArr",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formInline.deptId,
                        expression: "formInline.deptId",
                      },
                    ],
                    attrs: {
                      label: _vm.$t("searchModule.role"),
                      prop: "bossManagerRoleId",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "220px" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.formInline.bossManagerRoleId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "bossManagerRoleId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.bossManagerRoleId",
                        },
                      },
                      _vm._l(_vm.roleList, function (value) {
                        return _c("el-option", {
                          key: value.bossManagerRoleId,
                          attrs: {
                            label: value.bossManagerRoleName,
                            value: value.bossManagerRoleId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formInline.deptId,
                        expression: "formInline.deptId",
                      },
                    ],
                    attrs: { label: "管辖方式", prop: "manageType" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: _vm.formInline.deptId != 1 },
                        on: { change: _vm.typeChange },
                        model: {
                          value: _vm.formInline.manageType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "manageType",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.manageType",
                        },
                      },
                      _vm._l(_vm.manageList, function (v) {
                        return _c("el-option", {
                          key: v.code,
                          attrs: { label: v.desc, value: v.code },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formInline.deptId,
                        expression: "formInline.deptId",
                      },
                    ],
                    attrs: { label: "管辖区域" },
                  },
                  [
                    _c("areaTree", {
                      ref: "areaTreeCon",
                      attrs: { selectParentList: _vm.selectParentList },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "changeWith",
                    attrs: {
                      label: "管辖车场接入方式",
                      prop: "parkAccessType",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.formInline.parkAccessType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "parkAccessType",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.parkAccessType",
                        },
                      },
                      _vm._l(_vm.radioList, function (v) {
                        return _c(
                          "div",
                          {
                            key: v.code,
                            staticStyle: {
                              float: "left",
                              "margin-right": "10px",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: v.code } }, [
                              _vm._v(_vm._s(v.desc)),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "移动管理角色", prop: "manageRole" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "220px" },
                        on: { change: _vm.mobileRoleChange },
                        model: {
                          value: _vm.formInline.mobileRole,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "mobileRole", $$v)
                          },
                          expression: "formInline.mobileRole",
                        },
                      },
                      _vm._l(_vm.mangerRoleType, function (item) {
                        return _c("el-option", {
                          key: item.code,
                          attrs: { label: item.desc, value: item.code },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.formInline.mobileRole !== 3
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "移动端管辖区域", prop: "mobileArea" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            model: {
                              value: _vm.formInline.mobileArea,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "mobileArea",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.mobileArea",
                            },
                          },
                          [
                            _vm.formInline.mobileRole === 2
                              ? _c("el-option", {
                                  attrs: { label: "请选择", value: "" },
                                })
                              : _vm._e(),
                            _vm.formInline.mobileRole === 1
                              ? _c("el-option", {
                                  attrs: { label: "AIpark", value: "" },
                                })
                              : _vm._e(),
                            _vm._l(this.areaList, function (item) {
                              return _vm.formInline.mobileRole === 2
                                ? _c("el-option", {
                                    key: item.areaId,
                                    attrs: {
                                      label: item.areaName,
                                      value: item.areaId,
                                    },
                                  })
                                : _vm._e()
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "失效时间", prop: "date" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日",
                      },
                      on: {
                        change: function ($event) {
                          _vm.page = 1
                        },
                      },
                      model: {
                        value: _vm.formInline.effectiveDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "effectiveDate", $$v)
                        },
                        expression: "formInline.effectiveDate",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "20px" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "88px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.submitData },
                  },
                  [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "88px" },
                    attrs: { type: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }